import { getAllowedRoles } from '@/../config/userRoles';

export default [
  {
    path: '',
    name: 'product-admin',
    redirect: { name: 'market-templates' },
  },
  {
    path: 'market-templates',
    name: 'market-templates',
    component: () => import('@/components/templates/market-templates/MarketTemplates'),
    meta: {
      title: 'Market templates',
      allowedUserRoles: getAllowedRoles('templates'),
    },
  },
  {
    path: 'market-templates/:id',
    name: 'sport-template',
    component: () => import('@/components/templates/sport-template/Index'),
    meta: {
      title: 'Sport template',
      allowedUserRoles: getAllowedRoles('templates'),
    },
  },
  {
    path: 'mapping',
    name: 'mapping',
    component: () => import('@/components/mapping/common/MappingLayout'),
    meta: {
      title: 'Mapping',
      allowedUserRoles: getAllowedRoles('mapping'),
    },
    redirect: { name: 'eventsMapping' },
    children: [
      {
        path: 'events',
        name: 'eventsMapping',
        component: () => import('@/components/mapping/events-mapping/EventsMapping'),
      },
      {
        path: 'competitors',
        name: 'competitorsMapping',
        component: () => import('@/components/mapping/default-mapping/DefaultMapping'),
      },
      {
        path: 'regions',
        name: 'regionsMapping',
        component: () => import('@/components/mapping/default-mapping/DefaultMapping'),
      },
      {
        path: 'competitions',
        name: 'competitionsMapping',
        component: () => import('@/components/mapping/default-mapping/DefaultMapping'),
      },
      {
        path: 'players',
        name: 'playersMapping',
        component: () => import('@/components/mapping/default-mapping/DefaultMapping'),
      },
    ],
  },
  {
    path: 'market-configuration',
    name: 'market-config',
    redirect: { name: 'market-display' },
    children: [
      {
        path: 'display',
        name: 'market-display',
        component: () => import('@/components/market-display/MarketDisplay'),
        meta: {
          title: 'Market display',
          allowedUserRoles: getAllowedRoles('marketDisplay'),
        },
      },
      {
        path: 'groups',
        name: 'market-groups',
        component: () => import('@/components/market-groups/MarketGroups'),
        meta: {
          title: 'Market groups',
          allowedUserRoles: getAllowedRoles('marketGroups'),
        },
      },
    ],
  },
  {
    path: 'selection-configuration',
    name: 'selection-config',
    redirect: { name: 'selection-display' },
    children: [
      {
        path: 'display',
        name: 'selection-display',
        component: () => import('@/components/selection-display/SelectionDisplay'),
        meta: {
          title: 'Selection display',
          allowedUserRoles: getAllowedRoles('selectionDisplay'),
        },
      },
    ],
  },
  {
    path: 'competition-configuration',
    name: 'competition-configuration',
    component: () => import('@/components/competition-configuration/CompetitionConfiguration'),
    meta: {
      title: 'Competition Configuration',
      allowedUserRoles: getAllowedRoles('competitionConfiguration'),
    },
  },
  {
    path: 'manual-edit',
    name: 'manual-edit',
    component: () => import('@/components/manual-edit/Index'),
    redirect: { name: 'teamManualEdit' },
    meta: {
      title: 'Manual edit',
      allowedUserRoles: getAllowedRoles('manualEdit'),
    },
    children: [
      {
        path: 'teams',
        name: 'teamManualEdit',
        component: () => import('@/components/manual-edit/EditTeams'),
      },
      {
        path: 'players',
        name: 'playerManualEdit',
        component: () => import('@/components/manual-edit/EditPlayers'),
      },
    ],
  },
  {
    path: 'weights-configuration',
    name: 'weights-configuration',
    component: () => import('@/components/weights-configuration/Index'),
    meta: {
      title: 'PF Configuration - Weights',
      allowedUserRoles: getAllowedRoles('templates'),
    },
  },
];
